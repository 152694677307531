
import ApiService from "@/services/api.service";
import { AxiosResponse } from "axios";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export interface Warehouse {
  id: number
  name: number
}

export interface WarehouseCard {
  sales: number
  purchases: number
  ReturnPurchase: number
  ReturnSale: number
}

export interface WarehouseResponse {
  data: WarehouseCard
  warehouses: Warehouse[]
}

export default defineComponent({
  name: 'Warehouse',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const selectedWarehouse = ref<number | string>('')
    const warehouseOptions = ref<Warehouse[]>([])
    const cardData = reactive<WarehouseCard>({
      sales: 0,
      purchases: 0,
      ReturnPurchase: 0,
      ReturnSale: 0,
    })
    const isTabActived = (name: string): string => {
      if (route.name == name) return 'active'
      else return ''
    }
    const getCardData = async () => {
      try {
        const { data }: AxiosResponse<WarehouseResponse> = await ApiService.get('report/Warehouse_Report')
        cardData.sales = data.data.sales
        cardData.purchases = data.data.purchases
        cardData.ReturnPurchase = data.data.ReturnPurchase
        cardData.ReturnSale = data.data.ReturnSale
        warehouseOptions.value = data.warehouses
      } catch (error) {
        console.log(error);
      }
    }
    onMounted(async () => {
      getCardData()
      router.push('/reports/warehouse/sales')
    })
    const warehouseId = computed<string>({
      get() {
        return store.state.warehouseReport.warehouseId
      },
      set(payload) {
        store.commit('warehouseReport/setWarehouseId', payload)
      }
    })
    return {
      cardData,
      selectedWarehouse,
      warehouseOptions,
      isTabActived,
      warehouseId
    }
  }
})
