<template>
  <!-- <router-view /> -->
  <div class="row">
    <div class="col-lg-4">
      <div class="card dash-card">
        <div class="card-body">
          <img src="/icons/warehouse.svg" alt="warehouse">
        </div>
        <div class="card-actions">
          <div class="container">
            <div class="row">
              <div class="col-6 vertical-center">
                Warehouse Report
              </div>
              <div class="col-6 text-right">
                <router-link to="/reports/warehouse" class="btn btn-icon">
                  <span class="icon-arrow-right8"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card dash-card">
        <div class="card-body">
          <img src="/icons/users.svg" alt="users">
        </div>
        <div class="card-actions">
          <div class="container">
            <div class="row">
              <div class="col-6 vertical-center">
                Client Report
              </div>
              <div class="col-6 text-right">
                <router-link to="/reports/client" class="btn btn-icon">
                  <span class="icon-arrow-right8"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card dash-card">
        <div class="card-body">
          <img src="/icons/boxes.svg" alt="boxes">
        </div>
        <div class="card-actions">
          <div class="container">
            <div class="row">
              <div class="col-6 vertical-center">
                Product Report
              </div>
              <div class="col-6 text-right">
                <router-link to="/reports/product" class="btn btn-icon">
                  <span class="icon-arrow-right8"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: 'Index'
})
</script>

<style lang="sass">
.dash-card
  .card-body
    display: flex
    align-items: flex-end
    justify-content: center
    background-color: #E3E8FF
    padding-bottom: 0
    height: 112px
  .card-actions
    font-size: 16px
    line-height: 19px
    padding: 6px 8px
</style>