
import ApiService from "@/services/api.service";
import { defineComponent, onMounted, reactive, ref } from "vue";

export interface ProductByWarehouse {
  product_variant_id?: number
  Variant?: any
  code: string
  id: number
  name: string
  image: string
  qte_sale: number
  qte_purchase: number
  qte: number
  unitSale: string
  unitPurchase: string
  Net_price: number
}
export interface TopProductsYear {
  name: string
  value: string
}

export default defineComponent({
  name: "Product",
  setup(){
    const products = ref<ProductByWarehouse[]>([])
    const topProductYear = reactive<TopProductsYear>({
      name: '',
      value: ''
    })
    const print = async () => {
      try {
        const { data } = await ApiService.get('/products/export/Excel')
        const blob = new Blob([data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `product-export.csv`
        link.click()
      } catch (error) {
        console.log(error)
      }
    }

    const getProductByWarehouse = async () => {
      try {
        const { data } = await ApiService.get('/products/Warehouse/1?stock=1')
        products.value = data
      } catch (error) {
        console.log(error)
      }
    }

    const getTopProductYear = async () => {
      try {
        const { data } = await ApiService.get('/report/TopProducts_year')
        topProductYear.value = data[0].value
        topProductYear.name = data[0].name
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      getProductByWarehouse()
      getTopProductYear()
    })

    return {
      print,
      products,
      topProductYear
    }
  }
});
