
import ApiService from "@/services/api.service";
import { AxiosResponse } from "axios";
import { defineComponent, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

export interface TopClient {
  name: string
  value: string
}

export interface Client {
  id: number
  name: string
  code: number
  email: string
  country: string
  city: string
  phone: string
  stripe_id: string
  adresse: string
  created_at: string | null
  updated_at: string
  deleted_at: string | null
}

export interface ClientResponse {
  clients: Client[]
  totalRows: number
}

export default defineComponent({
  name: 'Client',
  setup(){
    const topClient = reactive({
      name: '',
      value: ''
    })
    const route = useRoute()

    const isTabActive = (match: String, index: number): Boolean => {
      if (typeof route.matched[index] === 'undefined') {
        return false
      } else {
        return Boolean(route.matched[index].path == match)
      }
    }

    const getTopClient = async () => {
      try {
        const { data }: AxiosResponse<TopClient[]> = await ApiService.get('/report/client_Top_Clients')
        topClient.name = data[0].name
        topClient.value = data[0].value
      } catch (error) {
        console.log(error);
      }
    } 

    onMounted(() => {
      getTopClient()
    })
    
    return {
      topClient,
      isTabActive
    }
  }
})
